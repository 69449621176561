import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "redux-persist";
// eslint-disable-next-line import/no-cycle
import configReducer from "./reducer/config";
import promoSlice from "./reducer/promoSlice";
import { brazeApi } from "../service/tracking/api/braze/api";

const rootReducer = combineReducers({
  config: configReducer,
  promo: promoSlice,
  [brazeApi.reducerPath]: brazeApi.reducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  blacklist: ["promo"],
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(brazeApi.middleware),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
