import { createSlice } from "@reduxjs/toolkit";

export type PromoElementStatus = "hidden" | "open" | "closed" | "disabled";
export type PromoElementPlatform = ("website" | "blog" | "academy")[];
type PromoElement = {
  status: PromoElementStatus;
  seen: boolean;
  platform: PromoElementPlatform;
  key: string;
};
type InitialStateType = {
  pop_up: PromoElement;
  banner: PromoElement;
  promo_utm: {
    type: "persistent" | "session" | "none";
    value?: string | null;
  };
};

const initialState: InitialStateType = {
  pop_up: {
    status: "hidden",
    seen: false,
    platform: ["academy"],
    key: "organic_survey",
  },
  banner: {
    status: "disabled",
    seen: false,
    platform: [],
    key: "organic_survey",
  },
  promo_utm: {
    type: "none",
    value: null,
  },
};

const slice = createSlice({
  name: "promo",
  initialState,
  reducers: {
    openPromoPopUp(state) {
      state.pop_up.status = "open";
    },
    closePromoPopUp(state) {
      state.pop_up.status = "closed";
    },
    setPromoPopUpSeen(state) {
      state.pop_up.seen = true;
    },
    openPromoBanner(state) {
      state.banner.status = "open";
    },
    closePromoBanner(state) {
      state.banner.status = "closed";
    },
    setPromoBannerSeen(state) {
      state.banner.seen = true;
    },
  },
});

export const {
  openPromoPopUp,
  closePromoPopUp,
  setPromoPopUpSeen,
  openPromoBanner,
  closePromoBanner,
  setPromoBannerSeen,
} = slice.actions;

export default slice.reducer;
