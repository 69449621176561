const BEGINNER = "beginner";
const INTERMEDIATE = "intermediate";
const EXPERT = "expert";

export const levelTrackingMap = {
  principiante: BEGINNER,
  intermedio: INTERMEDIATE,
  esperto: EXPERT,
  beginner: BEGINNER,
  intermediate: INTERMEDIATE,
  expert: EXPERT,
  débutant: BEGINNER,
  intermédiaire: INTERMEDIATE,
};
