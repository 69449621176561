import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCookie } from "../../utils/helpers";
// eslint-disable-next-line import/no-cycle
import { AppDispatch } from "../store";

type ConfigSLiceValue = {
  isWebView: boolean;
  theme: "dark" | "light";
};

const initialState: ConfigSLiceValue = {
  isWebView: false,
  theme: "light",
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setWebView: (state) => {
      state.isWebView = true;
    },
    removeWebView: (state) => {
      state.isWebView = false;
    },
    setTheme(state, action: PayloadAction<"dark" | "light">) {
      state.theme = action.payload;
    },
  },
});

const { setWebView, removeWebView, setTheme } = configSlice.actions;

export const updateWebView = () => (dispatch: AppDispatch) => {
  const webViewCookie = getCookie("webview");
  const themeCookie = getCookie("theme") || "light";
  if (webViewCookie) {
    dispatch(setWebView());
  } else {
    dispatch(removeWebView());
  }
  if (themeCookie === "dark" || themeCookie === "light") return dispatch(setTheme(themeCookie));
};

export default configSlice.reducer;
