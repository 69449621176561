export const articleEvents = {
  hero_back: "academy_article_hero_back" as const,
  hero_tag: "academy_article_hero_tag" as const,
  hero_share: "academy_article_hero_share" as const,
  hero_frontmatter: "academy_article_hero_frontmatter" as const,
  body_banner: "academy_article_body_banner" as const,
  body_related: "academy_article_body_related" as const,
};

export type ArticleEvents = typeof articleEvents;
