import { GatsbyImageProps } from "gatsby-plugin-image";
import { isEmpty } from "lodash";
import { TFunction } from "i18next";
import { SupportedLanguageValue } from "../feature/footer/types";

const isBrowser = typeof window !== "undefined";

export type JsonFromArrayProps =
  | string[]
  | GatsbyImageProps[]
  | JSX.Element[]
  | string
  | number;

function createArrayFromTranslationQuery<T extends string>(
  translator: TFunction,
  defaultQuery: string,
  numOfItems: number,
  imgArray: string[] | GatsbyImageProps[] | JSX.Element[],
  ...rest: T[]
): Array<Record<(typeof rest)[number], JsonFromArrayProps>> {
  const newArray = [];
  for (let i = 0; i < numOfItems; i += 1) {
    let tempObj = {} as Record<(typeof rest)[number], JsonFromArrayProps>;
    rest.forEach((el) => {
      tempObj = {
        ...tempObj,
        id: i + 1,
        [el]:
          (el === "icon" || el === "img") && (imgArray && imgArray.length) > 0
            ? imgArray[i]
            : translator(`${defaultQuery}.${el}_${i + 1}`),
      };
    });
    newArray.push(tempObj);
  }
  return newArray;
}

function changeLanguageHandler(lng: SupportedLanguageValue) {
  if (isBrowser) {
    try {
      localStorage.setItem("gatsby-i18next-language", lng);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
}

function setInitialLanguageValue() {
  if (isBrowser) {
    const landingPageLanguage = window.location.pathname.substring(1, 3);
    const userLanguage = localStorage.getItem("gatsby-i18next-language");
    const userBrowserLanguage = navigator.language.split("-")[0];
    if (userLanguage) {
      if (
        userLanguage.toLowerCase() !== "it" &&
        landingPageLanguage.toLowerCase() !== userLanguage.toLowerCase()
      ) {
        try {
          window.location.replace(
            `${process.env.GATSBY_CRAWL_URL}${userLanguage}`,
          );
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      } else if (
        userLanguage.toLocaleLowerCase() === "it" &&
        (landingPageLanguage.toLocaleLowerCase() === "en" ||
          landingPageLanguage.toLocaleLowerCase() === "fr")
      ) {
        window.location.replace(`${process.env.GATSBY_CRAWL_URL}`);
      }
    } else if (userBrowserLanguage) {
      if (userBrowserLanguage !== landingPageLanguage) {
        if (userBrowserLanguage.toLocaleLowerCase() === "it") {
          window.location.replace(`${process.env.GATSBY_CRAWL_URL}`);
        } else {
          window.location.replace(
            `${process.env.GATSBY_CRAWL_URL}${userBrowserLanguage}`,
          );
        }
      }
    }
    localStorage.setItem(
      "gatsby-i18next-language",
      userLanguage || userBrowserLanguage || landingPageLanguage || "it",
    );
  }
}

type FormattedPostDataProps = {
  date?: Queries.Maybe<string>;
  lng: string;
  dateOptions?: {
    month: "long";
    year: "numeric";
    day: "numeric";
  };
};

const getLocalePostFormattedDate = ({
  date,
  lng,
  dateOptions = {
    month: "long",
    year: "numeric",
    day: "numeric",
  },
}: FormattedPostDataProps) => {
  const defaultDate = new Date(Date.now()).toDateString();
  let postRowDate: Date | string | string[] = new Date(
    Date.parse(date || defaultDate),
  );

  postRowDate = new Intl.DateTimeFormat(
    lng.toLocaleLowerCase(),
    dateOptions,
  ).format(postRowDate);

  postRowDate = postRowDate.split(" ");

  const rawFormattedData = `${postRowDate[0]} ${postRowDate[1]}, ${postRowDate[2]}`;
  if (lng === "en") {
    return rawFormattedData.replace(",", "");
  }

  return rawFormattedData;
};

export const createGAEvent = (event: string, content: string) => () => {
  if (isBrowser) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      content,
    });
  }
};

export const triggerHeaderEvents = (content: "login" | "signup") => createGAEvent("click_academy_header", content);

function getCookie(name: string) {
  if (isBrowser) {
    const biscotti = document.cookie;
    let oggettoDiBiscotti: { [x: string]: string } = {};
    if (biscotti) {
      const arrayDiBiscotti = biscotti.split(";").map((el) => el.trim());
      arrayDiBiscotti.forEach((el) => {
        const biscotto = el.split("=");
        oggettoDiBiscotti = {
          ...oggettoDiBiscotti,
          [biscotto[0]]: biscotto[1],
        };
      });
    }

    if (!isEmpty(oggettoDiBiscotti)) {
      return oggettoDiBiscotti[name];
    }
  }
}

function setCookie(name: string, value: string, minutes?: number) {
  if (isBrowser) {
    let expires = "";
    if (minutes) {
      const date = new Date();
      date.setTime(date.getTime() + minutes * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
  }
}

const rowalizer = <T>(array: T[], itemPerRow = 3) => {
  const newArray: T[][] = [];
  const rows = Math.ceil(array.length / itemPerRow);
  // eslint-disable-next-line array-callback-return
  Array.from({ length: rows }, (_, index) => {
    const start = index * itemPerRow;
    const end = start + itemPerRow;
    newArray.push(array.slice(start, end));
  });

  return newArray;
};

function cleanStringFromHtlmTags(text?: string | null) {
  if (!text) {
    return;
  }
  return text
    .replace(/<\/?[^>]+(>|$)/g, "")
    .replace(/(<p>&nbsp;<\/p>)|(<h2><span>&nbsp;<\/span><\/h2>)/g, "");
}

function getSlugFromName(name: string) {
  let slug = name;
  if (name.includes(" ")) {
    slug = name.split(" ").join("-");
  }
  return slug.toLowerCase();
}

const handleSpaceInjection = (id: string, text: string, isMobile: boolean) => {
  const tempElement = document.getElementById(id) as HTMLElement;
  const bannerContent = tempElement?.textContent as string;

  if (isMobile) {
    const index =
      bannerContent && bannerContent.toLowerCase().indexOf(text.toLowerCase());

    if (index) {
      tempElement.innerHTML = `${bannerContent.slice(
        0,
        index,
      )}</br>${bannerContent.slice(index)}`;
    }
  } else {
    tempElement.innerHTML = bannerContent;
  }
};

const injectArticlePillsImage = () => {
  // if (isBrowser) {
  //   const pills = document.getElementsByClassName(`wp-block-academy-pills`)
  //   const rule = document.getElementsByClassName(`wp-block-academy-golden-rule`)
  //   const el = [...Array.from(pills), ...Array.from(rule)]
  //   if (!el || !el.length) {
  //     return
  //   }
  //   const bannerArray = Array.from(el)
  //   bannerArray.forEach(item => {
  //     const title = item.querySelector("h3")
  //     if (!title) {
  //       return
  //     }
  //     const text = title.innerHTML
  //     item.removeChild(title)
  //     const element = document.createElement("div")
  //     element.innerHTML = `
  //               <div class='golden-header'>
  //                   <img class='pills-image' alt='${text}' src='/golden-rule-icon.svg'/>
  //                   <h3>
  //                   ${text}
  //                   </h3>
  //               </div>
  //           `
  //     item.prepend(element)
  //   })
  // }
};

export const triggerClickToExchangeEvents = () => {
  if (!isBrowser) return;
  const { href } = window.location;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "click_to_exchange",
    content: href,
  });
};

export {
  createArrayFromTranslationQuery,
  setInitialLanguageValue,
  changeLanguageHandler,
  getLocalePostFormattedDate,
  isBrowser,
  getCookie,
  setCookie,
  rowalizer,
  cleanStringFromHtlmTags,
  getSlugFromName,
  handleSpaceInjection,
  injectArticlePillsImage,
};
