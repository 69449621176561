import React from "react";
import { ThemeProvider } from "styled-components";
import { YoungProvider as LegacyYoungProvider } from "@youngagency/young-ui";
import { YoungProvider } from "@youngagency/ui";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { store } from "./src/store/store";
import { GAProvider } from "./src/service/tracking";
import I18NextV2 from "./server/config/i18nextV2";

const HARD_CODED_THEME = {
  colorMode: {
    defaultMode: "light",
    mode: "light",
  },
} as const;

export const GlobalProvider = ({ element }: { element: React.ReactNode }) => (
  <GAProvider>
    <Provider store={store}>
      <ThemeProvider theme={{ layout: "light" }}>
        <YoungProvider
          options={{
            ...HARD_CODED_THEME,
            shouldApplyFontFamily: false,
          }}
        >
          <LegacyYoungProvider options={HARD_CODED_THEME}>
            <I18nextProvider i18n={I18NextV2}>{element}</I18nextProvider>
          </LegacyYoungProvider>
        </YoungProvider>
      </ThemeProvider>
    </Provider>
  </GAProvider>
);
