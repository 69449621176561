exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/Category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/Tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "slice---src-feature-footer-components-footer-tsx": () => import("./../../../src/feature/footer/components/Footer.tsx" /* webpackChunkName: "slice---src-feature-footer-components-footer-tsx" */),
  "slice---src-feature-navigation-components-category-hover-menu-tsx": () => import("./../../../src/feature/navigation/components/CategoryHoverMenu.tsx" /* webpackChunkName: "slice---src-feature-navigation-components-category-hover-menu-tsx" */),
  "slice---src-feature-navigation-components-header-tsx": () => import("./../../../src/feature/navigation/components/Header.tsx" /* webpackChunkName: "slice---src-feature-navigation-components-header-tsx" */),
  "slice---src-feature-navigation-components-hover-menu-tsx": () => import("./../../../src/feature/navigation/components/HoverMenu.tsx" /* webpackChunkName: "slice---src-feature-navigation-components-hover-menu-tsx" */),
  "slice---src-feature-navigation-components-product-hover-tsx": () => import("./../../../src/feature/navigation/components/ProductHover.tsx" /* webpackChunkName: "slice---src-feature-navigation-components-product-hover-tsx" */)
}

