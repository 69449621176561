import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import core_it from "../../src/locales/it/translation.json";
import core_en from "../../src/locales/en/translation.json";
import core_fr from "../../src/locales/fr/translation.json";

const supportedLngs = process.env.GATSBY_AVAILABLE_LANGUAGES
  ? process.env.GATSBY_AVAILABLE_LANGUAGES.split(",")
  : [];

const resources = {
  it: {
    translation: core_it,
  },
  en: {
    translation: core_en,
  },
  fr: {
    translation: core_fr,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "it",
  fallbackLng: process.env.GATSBY_DEFAULT_LANGUAGE,
  nsSeparator: false,
  supportedLngs,
});

export default i18next;
