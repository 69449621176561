import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  GetUserSubscriptionApiResponse, GetUserSubscriptionRequestBody, SubscribeNewsletterRequest, SubscribeSuccesNewsletterResponse,
} from "../../../../../server/api/types";

export const brazeApi = createApi({
  reducerPath: "brazeApi",
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    getUserSubscription: builder.query<GetUserSubscriptionApiResponse, GetUserSubscriptionRequestBody>({
      query: (body) => `/api/braze/get-user?email=${body.email}`,
    }),
    subscribeUser: builder.mutation<SubscribeSuccesNewsletterResponse, SubscribeNewsletterRequest["body"]>({
      query: (body) => ({
        url: "/api/braze/track-user",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useLazyGetUserSubscriptionQuery, useSubscribeUserMutation } = brazeApi;
